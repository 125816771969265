import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ProjectAlphaModule } from './public/project-alpha/project-alpha.module';
import { TeamsModule } from './teams/teams.module';
import { RolecapsModule } from './rolecaps/rolecaps.module';
import { UserAlignmentModule } from './user-alignment/user-alignment.module';
import { EntitlementManagerModule } from './entitlement-manager/entitlement-manager.module';
import { HighlightSearchPipe } from './public/project-alpha/highlight-search.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AppConfigService } from '../app/core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { ExternRouteComponent } from './public/project-alpha/extern-route/extern-route.component';
import { LogoutComponent } from './public/project-alpha/logout/logout.component';
import { SessionTimeoutComponent, FormatTimePipe } from './public/project-alpha/session-timeout/session-timeout.component';
import { PrivacyNoticeComponent } from './public/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './public/non-compatible/non-compatible.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { LoginComponent } from './public/login/login.component';
import { MenuComponent } from './core/components/menu/menu.component';
import { MenuItemComponent } from './core/components/menu/menu-item/menu-item.component';
import { MiniSpringboardComponent } from './core/components/mini-springboard/mini-springboard.component';
import { MiniSpringboardAppComponent } from './core/components/mini-springboard/mini-springboard-app/mini-springboard-app.component';

@NgModule({
  declarations: [
    AppComponent,
    ExternRouteComponent,
    LoginComponent,
    LogoutComponent,
    SessionTimeoutComponent,
    FormatTimePipe,
    PrivacyNoticeComponent,
    ContactUsComponent,
    NonCompatibleComponent,
    MenuComponent,
    MenuItemComponent,
    MiniSpringboardComponent,
    MiniSpringboardAppComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    ProjectAlphaModule,
    TeamsModule,
    RolecapsModule,
    UserAlignmentModule,
    EntitlementManagerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AppRoutingModule, // I must be last!! https://angular.io/guide/router#module-import-order-matters
    ServiceWorkerModule.register(
      'ngsw-worker.js'
    ) // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    HighlightSearchPipe,
    DatePipe,
    CookieService,
    {
      provide: SwRegistrationOptions,
      useFactory: (config: AppConfigService) => ({ enabled: config.getConfig('environment') !== 'developer' }),
      deps: [AppConfigService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
